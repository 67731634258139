import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table, Input } from "antd";
import axios from "axios";

export default function Countries() {
  const [countries, setCountries] = useState([]);
  const [countryIso, setCountryIso] = useState("");
  const [countryName, setCountryName] = useState("");

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ISO",
      dataIndex: "iso",
      key: "iso",
    },
  ];

  useEffect(() => {
    loadCountries();
  });

  const addCountry = async () => {
    await axios.post("https://flatlix.com:8080/api/country", {
      name: countryName,
      iso: countryIso,
    });

    setCountryName("");
    setCountryIso("");
  };

  const loadCountries = async () => {
    const result = await axios.get("https://flatlix.com:8080/api/countries");
    setCountries(result.data);
  };

  const countryNameChange = (e) => {
    setCountryName(e.target.value);
    console.log("Country ", e.target.value);
  };

  const countryIsoChange = (e) => {
    setCountryIso(e.target.value);
    console.log("ISO ", e.target.value);
  };

  return (
    <Row gutter={16} style={{ width: "100%" }}>
      <Col span={12}>
        <Card
          bordered={false}
          style={{ width: "100%", height: "96vh", textAlign: "center" }}
        >
          <Input
            placeholder="Country"
            onChange={countryNameChange}
            style={{ marginBottom: "10px" }}
          />
          <Input
            placeholder="ISO"
            onChange={countryIsoChange}
            style={{ marginBottom: "10px" }}
          />
          <Button type="primary" onClick={addCountry}>
            Add country
          </Button>
        </Card>
      </Col>
      <Col span={12}>
        <Card bordered={false} style={{ width: "100%", height: " 96vh" }}>
          <Table dataSource={countries} columns={columns} />
        </Card>
      </Col>
    </Row>
  );
}
