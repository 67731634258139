import logo from "./logo.svg";
import "./App.css";
import Countries from "./Countries";

function App() {
  return (
    <header className="App-header">
      <Countries />
    </header>
  );
}

export default App;
